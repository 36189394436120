/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router";
import CareContainer from "../../components/careContainer";
import Layout from "../../layout";

function About() {
  const navigate = useNavigate();
  const handleNavigateHome = () => {
    navigate("/");
    window.location.reload();
  };
  return (
    <Layout>
      <div className="tj-breadcrumb">
        <div className="container">
          <ul className="breadcrumb-list">
            <li>
              <a role="button" onClick={handleNavigateHome}>
                Home
              </a>
            </li>
            <li className="active">Aboutus</li>
          </ul>
        </div>
      </div>

      <section className="tj-welcome">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-7">
              <div className="about-info">
                <div className="tj-heading-style">
                  <h3>Who We Are</h3>
                </div>
                <p>
                  Shiva Cabs is one of the best and eminent Car and Coach
                  Providers in Bangalore, Karnataka. We offer our customers
                  excellent Car Rental Services, Bus Rental Services, Tempo
                  Traveler Services to our customers, with the goal that they
                  travel with comfort and besides, they can explore the popular
                  location.
                </p>
                <ul className="facts-list">
                  <li>
                    <strong className="fact-count">100</strong>
                    <i className="fa fa-percent"></i>
                    <span>Happy Customer</span>
                  </li>
                  <li>
                    <strong className="fact-count">200</strong>
                    <i className="fas fa-plus"></i>
                    <span>Luxury Cars</span>
                  </li>
                  <li>
                    <strong className="fact-count">12,000</strong>
                    <i className="fas fa-arrow-up"></i>
                    <span>Kilometers Driven</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-6 col-sm-5">
              <div className="welcome-banner">
                <img
                  src="images/who-we-are-cab.jpg"
                  alt="who-we-are"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tj-team slideInLeft animated delay-3s">
        <div className="container">
          <div className="row">
            <div className="tj-heading-style">
              <h3>Our Team</h3>
              <p>
                Lorem Ipsum passages, and more recently with desktop publishing
                software like aldus
              </p>
            </div>
            <div className="col-md-3 col-sm-3">
              <div className="member-box">
                <figure className="member-img">
                  <img
                    src="images/team-img.png"
                    alt="team-img"
                    loading="lazy"
                  />
                </figure>
                <div className="member-info">
                  <strong className="name">John Martin</strong>
                  <span>Luxury Driver</span>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-3">
              <div className="member-box">
                <figure className="member-img">
                  <img src="images/team-img.png" alt="" />
                </figure>
                <div className="member-info">
                  <strong className="name">Paul Heyman</strong>
                  <span>Van Driver</span>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-3">
              <div className="member-box">
                <figure className="member-img">
                  <img src="images/team-img.png" alt="" />
                </figure>
                <div className="member-info">
                  <strong className="name">Dwayne Johnson</strong>
                  <span>Limo Driver</span>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-3">
              <div className="member-box">
                <figure className="member-img">
                  <img src="images/team-img.png" alt="" />
                </figure>
                <div className="member-info">
                  <strong className="name">Fernandez</strong>
                  <span>Suv Driver</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CareContainer />
    </Layout>
  );
}

export default React.memo(About);

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router";
import Layout from "../../layout";

function Policy() {
  const navigate = useNavigate();
  const handleNavigateHome = () => {
    navigate("/");
    window.location.reload();
  };
  return (
    <Layout>
      <div className="tj-inner-banner">
        <div className="container">
          <h2>Policy</h2>
        </div>
      </div>

      <div className="tj-breadcrumb">
        <div className="container">
          <ul className="breadcrumb-list">
            <li>
              <a role="button" onClick={handleNavigateHome}>
                Home
              </a>
            </li>
            <li className="active">Policy</li>
          </ul>
        </div>
      </div>

      <section className="tj-policy-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="tj-heading-style">
                <h3>Why We Collect Data</h3>
              </div>
              <p>
                To take a trivial example, which of us ever undertakes laborious
                physical exercise, except to obtain some advantage from it? But
                who has any right to find fault with a man who chooses to enjoy
                a pleasure that has no annoying consequences, or one who avoids
                a pain that produces no resultant pleasure. I must explain to
                you how all this mistaken idea of denouncing pleasure and
                praising pain was born and I will give you a complete account of
                the system, and expound the actual teachings of the great
                explorer of the truth, the master-builder of human happiness. No
                one rejects, dislikes, or avoids pleasure itself, because it is
                pleasure, but because those who do not know how to pursue
                pleasure rationally encounter consequences that are extremely
                painful. Nor again is there anyone who loves or pursues or
                desires to obtain pain of itself, because it is pain, but
                because occasionally circumstances occur in which toil and pain
                can procure him some great pleasure.
              </p>
              <p>
                No one rejects, dislikes, or avoids pleasure itself, because it
                is pleasure, but because those who do not know how to pursue
                pleasure rationally encounter consequences that are extremely
                painful. Nor again is there anyone who loves or pursues or
                desires to obtain pain of itself, because it is pain, but
                because occasionally circumstances occur in which toil and pain
                can procure him some great pleasure. There are many variations
                of passages of Lorem Ipsum available, but the majority have
                suffered alteration in some form, by injected humour, or
                randomised words which don't look even slightly believable.
              </p>
              <h4>Third Parties</h4>
              <p>
                No one rejects, dislikes, or avoids pleasure itself, because it
                is pleasure, but because those who do not know how to pursue
                pleasure rationally encounter consequences that are extremely
                painful. Nor again is there anyone who loves or pursues or
                desires to obtain pain of itself, because it is pain, but
                because occasionally circumstances occur in which toil and pain
                can procure him some great pleasure.
              </p>
              <h4>Our Contact Information</h4>
              <ul>
                <li>10A, PrimeCab, San Andreno, United States. </li>
                <li>primecab@booking.com</li>
                <li>+1-333-444-555</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="tj-cal-to-action">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-4">
              <div className="cta-box">
                <img src="images/cta-icon1.png" alt="" />
                <div className="cta-text">
                  <strong>Best Price Guaranteed</strong>
                  <p>
                    A more recently with desktop softy like aldus page maker.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="cta-box">
                <img src="images/cta-icon2.png" alt="" />
                <div className="cta-text">
                  <strong>24/7 Customer Care</strong>
                  <p>
                    A more recently with desktop softy like aldus page maker.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="cta-box">
                <img src="images/cta-icon3.png" alt="" />
                <div className="cta-text">
                  <strong>Easy Bookings</strong>
                  <p>
                    A more recently with desktop softy like aldus page maker.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default React.memo(Policy);

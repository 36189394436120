import React from "react";
// import Loader from "../components/loader";
import Header from "../components/header";
import Footer from "../components/footer";
// import ThemeSwitcher from "../components/theme";
import WhatsappBooking from "../components/whatsApp";
// import Notify from "../components/notiStack";

function Layout({ children }) {
  return (
    <div className="tj-wrapper">
      {/* <Loader /> */}
      {/* <ThemeSwitcher /> */}
      <Header />
      {children}
      <WhatsappBooking />
      <Footer />
      {/* <Notify /> */}
    </div>
  );
}

export default React.memo(Layout);

import axios from "axios";

const baseURL = `${process.env.REACT_APP_PUBLIC_URL}/api/shiva-cab-requests/`;
// const baseURL = "http://127.0.0.1:8000/api/shiva-cab-requests/";
const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    // "Content-Type": "application/json",
    // "cache-control":'no-cache'
    //'Authorization': 'token <your-token-here> -- https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/creating-a-personal-access-token'
  },
});

export default axiosInstance;

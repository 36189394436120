/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router";
import Layout from "../../layout";

function NotFound() {
  const navigate = useNavigate();
  const handleNavigateHome = () => {
    navigate("/");
    window.location.reload();
  };

  return (
    <Layout>
      <div className="tj-inner-banner">
        <div className="container">
          <h2>404</h2>
        </div>
      </div>

      <div className="tj-breadcrumb">
        <div className="container">
          <ul className="breadcrumb-list">
            <li>
              <a role="button" onClick={handleNavigateHome}>
                Home
              </a>
            </li>
            <li className="active">404</li>
          </ul>
        </div>
      </div>

      <section className="tj-p404-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <strong className="p404-title">404</strong>
              <h2>Page Not Found</h2>
              <div className="p404-info">
                <p>We can’t seem to find the page you’re looking for</p>
                <a role="button" onClick={handleNavigateHome}>
                  <i className="fa fa-arrow-circle-left" aria-hidden="true"></i>{" "}
                  Back to Home
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tj-cal-to-action">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-4">
              <div className="cta-box">
                <img src="images/cta-icon1.png" alt="" />
                <div className="cta-text">
                  <strong>Best Price Guaranteed</strong>
                  <p>
                    A more recently with desktop softy like aldus page maker.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="cta-box">
                <img src="images/cta-icon2.png" alt="" />
                <div className="cta-text">
                  <strong>24/7 Customer Care</strong>
                  <p>
                    A more recently with desktop softy like aldus page maker.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="cta-box">
                <img src="images/cta-icon3.png" alt="" />
                <div className="cta-text">
                  <strong>Easy Bookings</strong>
                  <p>
                    A more recently with desktop softy like aldus page maker.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default React.memo(NotFound);

import { set } from "date-fns";
import { createContext, useState } from "react";

const AppContext = createContext({
	distance: 0,
	totalPrice: 0,
	setDistance: () => {},
	setTotalPrice: () => {},
});

const ContextProvider = ({ children }) => {
	const [distance, setDistance] = useState(0);
	const [totalPrice, setTotalPrice] = useState(0);

	return (
		<AppContext.Provider
			value={{ distance, setDistance, totalPrice, setTotalPrice }}
		>
			{children}
		</AppContext.Provider>
	);
};

export { ContextProvider, AppContext };

import React, { useEffect, useState } from "react";
import { submitCabRequestForm, getAllCabs } from "../../services/requests";
import { IsoFormatDate } from "../../services/dateFormatters";
import Notify from "../../components/notiStack";
import { Button } from "@mui/material";

export default function LocalBookingForm({ handleFormClose }) {
	const [username, setUsername] = useState("");
	const [mobileNumber, setMobileNumber] = useState("");
	const [origin, setOrigin] = useState("");
	const [destination, setDestination] = useState("");
	const [journeyDate, setJourneyDate] = useState(IsoFormatDate(new Date()));
	const [cabType, setCabType] = useState({});
	const [notifyOptions, setNotifyOptions] = useState({
		open: false,
		duration: 2000,
		message: "",
		status: "",
	});
	const [journeyType, setJourneyType] = useState("One Way");
	const [cabTypesData, setCabTypesData] = useState([]);

	const getInitialValues = () => {
		setUsername("");
		setMobileNumber("");
		setOrigin("");
		setDestination("");
		setCabType({});
		setJourneyDate(IsoFormatDate(new Date()));
		setJourneyType("One Way");
	};

	const getAllCabsData = () => {
		getAllCabs().then((data) => {
			setCabTypesData(data);
		});
	};

	useEffect(() => {
		getInitialValues();
		getAllCabsData();
	}, []);

	const handleJourneyType = (value) => {
		setJourneyType(value);
	};

	const handleInputChange = (event, setValue) => {
		setValue(event.target.value);
	};
	const handleCabTypeInputChange = (value) => {
		setCabType(value);
	};

	const handleMobileNumberChange = (event) => {
		const { value } = event.target;
		const regex = /^\d*$/;
		if (regex.test(value) && value.length <= 10) {
			setMobileNumber(value);
		}
	};

	const handleRequestFormSubmit = () => {
		if (!username) {
			setNotifyOptions({
				open: true,
				message: "Please enter name",
				duration: 2000,
				status: "error",
			});
			return;
		} else if (!mobileNumber.length === 10) {
			setNotifyOptions({
				open: true,
				message: "Please enter valid mobile number",
				duration: 2000,
				status: "error",
			});
			return;
		} else if (!origin) {
			setNotifyOptions({
				open: true,
				message: "Please enter pickup location",
				duration: 2000,
				status: "error",
			});
			return;
		}
		const payload = {
			user_name: username,
			mobile_number: mobileNumber,
			origin: origin,
			destination: destination,
			journey_date: journeyDate,
			cab_type_id: JSON.parse(cabType).id ?? JSON.parse(cabType).id,
			journey_type: journeyType,
		};
		submitCabRequestForm(payload).then((response) => {
			if (response === 201) {
				setNotifyOptions({
					open: true,
					message: (
						<p style={{ fontSize: "14px", color: "white" }}>
							Your request has been received! Expect a prompt response shortly.
							Need immediate assistance?{" "}
							<a
								href="https://wa.me/+918884150555"
								target="_blank"
								style={{ color: "#dd3751" }}
							>
								Click here
							</a>{" "}
							to chat now.
						</p>
					),
					duration: 3000,
					status: "success",
				});
				getInitialValues();
			} else {
				setNotifyOptions({
					open: true,
					message: (
						<p>
							Something went wrong. Need immediate assistance?{" "}
							<a
								href="https://wa.me/+918884150555"
								target="_blank"
								style={{ color: "#dd3751" }}
							>
								Click here
							</a>{" "}
							to chat now.
						</p>
					),
					duration: 3000,
					status: "error",
				});
			}
		});
	};

	const handleClose = () => {
		setNotifyOptions({
			open: false,
			duration: 2000,
			message: "",
			status: "",
		});
	};

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			<div
				id="booking-form-div"
				style={{
					width: "100%",
					maxWidth: "360px",
				}}
			>
				<div className="trip-outer">
					<div className="trip-type-tabs">
						<ul className="nav nav-tabs">
							<li className="active">
								<a
									onClick={() => handleJourneyType("One Way")}
									data-toggle="tab"
								>
									{" "}
									One Way{" "}
								</a>
							</li>
							<li>
								<a
									onClick={() => handleJourneyType("Two Way")}
									data-toggle="tab"
								>
									{" "}
									Two Way{" "}
								</a>
							</li>
						</ul>
					</div>
					<div className="tab-content">
						<div
							className="tab-pane active"
							id="one-way"
						>
							<div className="trip-type-frm">
								<div className="field-outer">
									<span className="fa fa-person"></span>
									<input
										type="text"
										placeholder="Name"
										value={username}
										onChange={(event) => handleInputChange(event, setUsername)}
									/>
								</div>
								<div className="field-outer">
									<span className="fa fa-phone"></span>
									<input
										type="text"
										placeholder="Mobile Number"
										value={mobileNumber}
										onChange={(event) => handleMobileNumberChange(event)}
									/>
								</div>
								<div className="field-outer">
									<span className="fas fa-search"></span>
									<input
										type="text"
										placeholder="Pickup Locations"
										value={origin}
										onChange={(event) => handleInputChange(event, setOrigin)}
									/>
								</div>
								<div className="field-outer">
									<span className="fas fa-search"></span>
									<input
										type="text"
										placeholder="Drop Locations"
										value={destination}
										onChange={(event) =>
											handleInputChange(event, setDestination)
										}
									/>
								</div>
								<div className="field-outer">
									{/* <span className="fas fa-calendar-alt"></span> */}
									<input
										type="datetime-local"
										placeholder="Select your Date"
										value={journeyDate}
										onChange={(event) =>
											handleInputChange(event, setJourneyDate)
										}
									/>
								</div>
								<div className="field-outer">
									<span className="fa fa-angle-down"></span>
									<select
										value={cabType}
										onChange={(event) =>
											handleCabTypeInputChange(event.target.value)
										}
									>
										<option value={JSON.stringify({})}>Select Cab Type</option>
										{cabTypesData.map((item) => (
											<option
												key={item.id}
												value={JSON.stringify(item)}
											>
												{item.vehicle_name}
											</option>
										))}
									</select>
								</div>
								{/* <div className="field-outer">
                        <input
                          type="checkbox"
                          id="promo_code"
                          value={promoCode}
                          onChange={(event) =>
                            handleInputChange(event, setPromoCode)
                          }
                        />
                        <label htmlFor="promo_code">
                          I Have Promotional Code
                        </label>
                      </div> */}
								<button
									className="search-btn"
									style={{
										width: "100%",
									}}
									onClick={() => handleRequestFormSubmit()}
								>
									Submit
									{/* <i
                          className="fa fa-arrow-circle-right"
                          aria-hidden="true"
                        ></i> */}
								</button>
								<Button
									fullWidth
									variant="contained"
									size="large"
									color="inherit"
									sx={{
										marginTop: "10px",
										height: "48px",
									}}
									onClick={handleFormClose}
								>
									Close
									{/* <i
                          className="fa fa-arrow-circle-right"
                          aria-hidden="true"
                        ></i> */}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
			{notifyOptions?.open && (
				<Notify
					open={notifyOptions.open}
					message={notifyOptions.message}
					duration={notifyOptions.duration}
					status={notifyOptions.status}
					onClose={handleClose}
				/>
			)}
		</div>
	);
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router";
import CareContainer from "../../components/careContainer";
import Layout from "../../layout";

function Blog() {
  const navigate = useNavigate();
  const handleNavigateHome = () => {
    navigate("/");
    window.location.reload();
  };
  return (
    <Layout>
      <div className="tj-breadcrumb">
        <div className="container">
          <ul className="breadcrumb-list">
            <li>
              <a role="button" onClick={handleNavigateHome}>
                Home
              </a>
            </li>
            <li className="active">Blog</li>
          </ul>
        </div>
      </div>

      <section className="tj-news">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="tj-heading-style">
                <h3>Our Blog</h3>
                <p>
                  Lorem Ipsum passages, and more recently with desktop
                  publishing software like aldus pageMaker including versions.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="news-box">
                <figure>
                  <img src="images/news_img.png" alt="" />
                </figure>

                <div className="news-detail">
                  <h4>Repeat predefined chunks</h4>
                  <p>
                    A more recently with desktop softy like aldo page maker
                    repeat predefined.
                  </p>
                  <ul>
                    <li>
                      <i className="far fa-clock"></i> Sep 19, 2018
                    </li>
                    <li>
                      <i className="far fa-comments"></i> 29
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="news-box">
                <figure>
                  <img src="images/news_img.png" alt="" />
                </figure>

                <div className="news-detail">
                  <h4>Making it look readable</h4>
                  <p>
                    A more recently with desktop softy like aldo page maker
                    repeat predefined.
                  </p>
                  <ul>
                    <li>
                      <i className="far fa-clock"></i> Sep 19, 2018
                    </li>
                    <li>
                      <i className="far fa-comments"></i> 29
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-12">
              <div className="news-list">
                <ul className="news-outer">
                  <li>
                    <figure>
                      <img src="images/news_thumb.png" alt="" />
                    </figure>
                    <div className="news-info">
                      <h4>Various versions have evole over the years</h4>
                      <ul className="news-meta">
                        <li>
                          <i className="far fa-clock"></i> Sep 19, 2018
                        </li>
                        <li>
                          <i className="far fa-comments"></i> 29
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <figure>
                      <img src="images/news_thumb.png" alt="" />
                    </figure>
                    <div className="news-info">
                      <h4>A galley of type and scrambe it to make a type</h4>
                      <ul className="news-meta">
                        <li>
                          <i className="far fa-clock"></i> Sep 19, 2018
                        </li>
                        <li>
                          <i className="far fa-comments"></i> 29
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <figure>
                      <img src="images/news_thumb.png" alt="" />
                    </figure>
                    <div className="news-info">
                      <h4>Treatise on the theory of ethics very popular</h4>
                      <ul className="news-meta">
                        <li>
                          <i className="far fa-clock"></i> Sep 19, 2018
                        </li>
                        <li>
                          <i className="far fa-comments"></i> 29
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="clear"></div>

            <div className="col-md-4 col-sm-6">
              <div className="news-box">
                <figure>
                  <img src="images/news_img.png" alt="" />
                </figure>

                <div className="news-detail">
                  <h4>Best Rates For Taxi Service</h4>
                  <p>
                    A more recently with desktop softy like aldo page maker
                    repeat predefined.
                  </p>
                  <ul>
                    <li>
                      <i className="far fa-clock"></i> Sep 15, 2018
                    </li>
                    <li>
                      <i className="far fa-comments"></i> 50
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="news-box">
                <figure>
                  <img src="images/news_img.png" alt="" />
                </figure>

                <div className="news-detail">
                  <h4>Install App and get free ride</h4>
                  <p>
                    A more recently with desktop softy like aldo page maker
                    repeat predefined.
                  </p>
                  <ul>
                    <li>
                      <i className="far fa-clock"></i> Sep 6, 2018
                    </li>
                    <li>
                      <i className="far fa-comments"></i> 12
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-12">
              <div className="news-list">
                <ul className="news-outer">
                  <li>
                    <figure>
                      <img src="images/news_thumb.png" alt="" />
                    </figure>
                    <div className="news-info">
                      <h4>Various versions have evole over the years</h4>
                      <ul className="news-meta">
                        <li>
                          <i className="far fa-clock"></i> Sep 29, 2018
                        </li>
                        <li>
                          <i className="far fa-comments"></i> 20
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <figure>
                      <img src="images/news_thumb.png" alt="" />
                    </figure>
                    <div className="news-info">
                      <h4>Get cheap ride to reach where you want</h4>
                      <ul className="news-meta">
                        <li>
                          <i className="far fa-clock"></i> Apr 19, 2018
                        </li>
                        <li>
                          <i className="far fa-comments"></i> 19
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <figure>
                      <img src="images/news_thumb.png" alt="" />
                    </figure>
                    <div className="news-info">
                      <h4>Get taxi service nearby your home</h4>
                      <ul className="news-meta">
                        <li>
                          <i className="far fa-clock"></i> Aug 12, 2018
                        </li>
                        <li>
                          <i className="far fa-comments"></i> 25
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CareContainer />
    </Layout>
  );
}

export default React.memo(Blog);

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Layout from "../../layout";
import { useNavigate } from "react-router";

function Terms() {
  let navigate = useNavigate();

  const handleNavigateHome = () => {
    navigate("/");
  };
  return (
    <Layout>
      <div className="tj-breadcrumb">
        <div className="container">
          <ul className="breadcrumb-list">
            <li>
              <a onClick={handleNavigateHome}>Home</a>
            </li>
            <li className="active">Terms and Conditions</li>
          </ul>
        </div>
      </div>
      <section className="tj-app">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="app-info">
                <div className="tj-heading-style">
                  <h3>Terms</h3>
                </div>
                <ul className="feature-list">
                  <li>
                    <i className="fa fa" aria-hidden="true">1.</i>Usual
                    Driver bata timings are from 6 AM to 10PM.
                  </li>
                  <li>
                    <i className="fa fa" aria-hidden="true">2.</i>If the
                    driver drives after 10PM and before 6AM then there would be
                    an extra driver bata .
                  </li>
                  <li>
                    <i className="fa fa" aria-hidden="true">3.</i>Distance
                    is calculated from Office to Office.
                  </li>
                  <li>
                    <i className="fa fa" aria-hidden="true">4.</i>Driver
                    allowance will take care of his food and shelter.
                  </li>
                  <li>
                    <i className="fa fa" aria-hidden="true">5.</i>One day
                    means one calendar day (from midnight 12 to midnight 12).
                  </li>
                  <li>
                    <i className="fa fa" aria-hidden="true">6.</i>Local
                    usage is not allowed in Bangalore.
                  </li>
                  <li>
                    <i className="fa fa" aria-hidden="true">7.</i>The final
                    amount will depend on actual traveled distance and days.
                  </li>
                  <li>
                    <i className="fa fa" aria-hidden="true">8.</i>There is
                    NO GST as of now.
                  </li>
                  <li>
                    <i className="fa fa" aria-hidden="true">9.</i>State
                    Permit, Parking and Toll charges will be extra, and advised
                    to get the information from us.
                  </li>
                  <li>
                    <i className="fa fa" aria-hidden="true">10.</i>A/C wont
                    work in hillstations.
                  </li>
                  <li>
                    <i className="fa fa" aria-hidden="true">11.</i>FULL
                    refund on cancellation before 24 hours.
                  </li>
                  <li>
                    <i className="fa fa" aria-hidden="true">12.</i>NO refund
                    on cancellation before 12 hours.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default React.memo(Terms);

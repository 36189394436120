/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Layout from "../../layout";
import { useNavigate } from "react-router";

function FAQ() {
  let navigate = useNavigate();

  const handleNavigateHome = () => {
    navigate("/");
  };
  return (
    <Layout>
      <div className="tj-breadcrumb">
        <div className="container">
          <ul className="breadcrumb-list">
            <li>
              <a onClick={handleNavigateHome}>Home</a>
            </li>
            <li className="active">FAQ</li>
          </ul>
        </div>
      </div>
      <section className="tj-faq">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="tj-heading-style">
                <h3>Frequent Questions</h3>
              </div>
              <div className="tj-accordion-holder">
                <div
                  className="panel-group"
                  id="accordion"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="tj-tab-1">
                      <div className="panel-title">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#tj-tab-collapse1"
                          aria-expanded="true"
                          aria-controls="tj-tab-collapse1"
                        >
                          1. Are you a licenced company?
                        </a>
                      </div>
                    </div>
                    <div
                      id="tj-tab-collapse1"
                      className="panel-collapse collapse in"
                      role="tabpanel"
                      aria-labelledby="tj-tab-1"
                    >
                      <div className="panel-body">
                        <div className="panel-text">
                          <p>
                            Yes. We are licenced by the public carriage office
                            under Labour department of karnataka
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="tj-tab-2">
                      <div className="panel-title">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#tj-tab-collapse2"
                          aria-expanded="false"
                          aria-controls="tj-tab-collapse2"
                          className="collapsed"
                        >
                          2. Is your service available day and night?
                        </a>
                      </div>
                    </div>
                    <div
                      id="tj-tab-collapse2"
                      className="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="tj-tab-2"
                    >
                      <div className="panel-body">
                        <div className="panel-text">
                          <p>
                            Yes. We operate 24 hours 7 days a week, all through
                            the year.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="tj-tab-3">
                      <div className="panel-title">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#tj-tab-collapse3"
                          aria-expanded="false"
                          aria-controls="tj-tab-collapse3"
                          className="collapsed"
                        >
                          3. How do I book a Cab?
                        </a>
                      </div>
                    </div>
                    <div
                      id="tj-tab-collapse3"
                      className="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="tj-tab-3"
                    >
                      <div className="panel-body">
                        <div className="panel-text">
                          <p>
                            You can book a cab either through Phone or Website,
                            To book through phone please call 08884150555 , To
                            book a cab through the website, visit our home page:
                            www.shivacabs.in
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="tj-tab-4">
                      <div className="panel-title">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#tj-tab-collapse4"
                          aria-expanded="false"
                          aria-controls="tj-tab-collapse4"
                          className="collapsed"
                        >
                          4. Do I have to pay toll charge when I travel ?
                        </a>
                      </div>
                    </div>
                    <div
                      id="tj-tab-collapse4"
                      className="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="tj-tab-4"
                    >
                      <div className="panel-body">
                        <div className="panel-text">
                          <p>
                            Yes, you will be paying &#39;Toll charge&#39;- at
                            the actual,, when you are crossing the toll post
                            while travelling, However, in case you have booked a
                            cab &amp; the cab is crossing the toll post while
                            coming to pick you up, then you need not pay the
                            toll charges.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="tj-tab-5">
                      <div className="panel-title">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#tj-tab-collapse5"
                          aria-expanded="false"
                          aria-controls="tj-tab-collapse5"
                          className="collapsed"
                        >
                          5. How do I pay after the completion of my trip?
                        </a>
                      </div>
                    </div>
                    <div
                      id="tj-tab-collapse5"
                      className="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="tj-tab-5"
                    >
                      <div className="panel-body">
                        <div className="panel-text">
                          <p>
                            we accepts both Cash and Cashless payments i.e. you
                            can pay via Cash as well as via internet banking
                            after completion of your trip
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="tj-tab-6">
                      <div className="panel-title">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#tj-tab-collapse6"
                          aria-expanded="false"
                          aria-controls="tj-tab-collapse6"
                          className="collapsed"
                        >
                          6. Do I need to pay any extra amount apart from the
                          one listed here on website?
                        </a>
                      </div>
                    </div>
                    <div
                      id="tj-tab-collapse6"
                      className="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="tj-tab-6"
                    >
                      <div className="panel-body">
                        <div className="panel-text">
                          <p>
                            No, the amount listed is the final amount you need
                            to Pay. Our quotes are completely transparent and we
                            list all the applicable charges upfront.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="tj-tab-7">
                      <div className="panel-title">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#tj-tab-collapse7"
                          aria-expanded="false"
                          aria-controls="tj-tab-collapse7"
                          className="collapsed"
                        >
                          7. Can i make a booking for a round-trip with shiva
                          cabs ?
                        </a>
                      </div>
                    </div>
                    <div
                      id="tj-tab-collapse7"
                      className="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="tj-tab-7"
                    >
                      <div className="panel-body">
                        <div className="panel-text">
                          <p>
                            shiva cabs specializes in inter-city transportation
                            and focuses specifically on providing quality
                            service at fair prices. You can make reservations on
                            our platform (web, phone) for one-way, round-trip or
                            multi-city trips.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="tj-tab-8">
                      <div className="panel-title">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#tj-tab-collapse8"
                          aria-expanded="false"
                          aria-controls="tj-tab-collapse8"
                          className="collapsed"
                        >
                          8. Can I smoke in the car?
                        </a>
                      </div>
                    </div>
                    <div
                      id="tj-tab-collapse8"
                      className="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="tj-tab-8"
                    >
                      <div className="panel-body">
                        <div className="panel-text">
                          <p>
                            No. We operate a strict no smoking policy in all our
                            vehicles.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="tj-tab-9">
                      <div className="panel-title">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#tj-tab-collapse9"
                          aria-expanded="false"
                          aria-controls="tj-tab-collapse9"
                          className="collapsed"
                        >
                          9. Do you provide A/C cars?
                        </a>
                      </div>
                    </div>
                    <div
                      id="tj-tab-collapse9"
                      className="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="tj-tab-9"
                    >
                      <div className="panel-body">
                        <div className="panel-text">
                          <p>
                            We provide only A/C cars. The A/C is always working
                            condition. The only exception is for trips to hilly
                            regions where the A/C should be turned off to
                            prevent overload on the car engine.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="tj-tab-10">
                      <div className="panel-title">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#tj-tab-collapse10"
                          aria-expanded="false"
                          aria-controls="tj-tab-collapse10"
                          className="collapsed"
                        >
                          10. How can I cancel my booking?
                        </a>
                      </div>
                    </div>
                    <div
                      id="tj-tab-collapse10"
                      className="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="tj-tab-10"
                    >
                      <div className="panel-body">
                        <div className="panel-text">
                          <p>
                            You may cancel your booking at anytime. Bookings
                            cancelled within 24hours of pickup time will include
                            a cancellation fee that will be charged to your
                            account. If you cancel your booking before 24 hours
                            before your pickup time, the booking may be
                            cancelled at free of cost.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="tj-tab-11">
                      <div className="panel-title">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#tj-tab-collapse11"
                          aria-expanded="false"
                          aria-controls="tj-tab-collapse11"
                          className="collapsed"
                        >
                          11. What car categories do you provide?
                        </a>
                      </div>
                    </div>
                    <div
                      id="tj-tab-collapse11"
                      className="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="tj-tab-11"
                    >
                      <div className="panel-body">
                        <div className="panel-text">
                          <p>
                            Economy - includes models like Indica and Indigo 
                            Compact cars - includes models like Etios, D’zire 
                            Family Lux - Innova Additional services like tempo
                            traveller or buses are available.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="tj-tab-12">
                      <div className="panel-title">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#tj-tab-collapse12"
                          aria-expanded="false"
                          aria-controls="tj-tab-collapse12"
                          className="collapsed"
                        >
                          12. How many people can travel per taxi?
                        </a>
                      </div>
                    </div>
                    <div
                      id="tj-tab-collapse12"
                      className="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="tj-tab-12"
                    >
                      <div className="panel-body">
                        <div className="panel-text">
                          <p>
                            Our seating configurations are listed when you are
                            making a reservation.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="tj-tab-13">
                      <div className="panel-title">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#tj-tab-collapse13"
                          aria-expanded="false"
                          aria-controls="tj-tab-collapse13"
                          className="collapsed"
                        >
                          13. What if I have a lot of luggage to be carried
                          during the journey?
                        </a>
                      </div>
                    </div>
                    <div
                      id="tj-tab-collapse13"
                      className="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="tj-tab-13"
                    >
                      <div className="panel-body">
                        <div className="panel-text">
                          <p>
                            When making the reservation, please be clear on your
                            requirements. We mention the number of passengers,
                            and also the number of luggage.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="tj-tab-14">
                      <div className="panel-title">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#tj-tab-collapse14"
                          aria-expanded="false"
                          aria-controls="tj-tab-collapse14"
                          className="collapsed"
                        >
                          14. Left something in the cab?
                        </a>
                      </div>
                    </div>
                    <div
                      id="tj-tab-collapse14"
                      className="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="tj-tab-14"
                    >
                      <div className="panel-body">
                        <div className="panel-text">
                          <p>
                            Don’t worry give us a Call at 08884150555 as soon as
                            possible and we will try and locate your item.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="tj-tab-15">
                      <div className="panel-title">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#tj-tab-collapse15"
                          aria-expanded="false"
                          aria-controls="tj-tab-collapse15"
                          className="collapsed"
                        >
                          15. Is there any facility for recharging laptops and
                          mobiles?
                        </a>
                      </div>
                    </div>
                    <div
                      id="tj-tab-collapse15"
                      className="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="tj-tab-15"
                    >
                      <div className="panel-body">
                        <div className="panel-text">
                          <p>Yes, such facility is provided.</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="tj-tab-16">
                      <div className="panel-title">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#tj-tab-collapse16"
                          aria-expanded="false"
                          aria-controls="tj-tab-collapse16"
                          className="collapsed"
                        >
                          16. I don&#39;t have a credit card. Can I still buy a
                          bus ticket?
                        </a>
                      </div>
                    </div>
                    <div
                      id="tj-tab-collapse16"
                      className="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="tj-tab-16"
                    >
                      <div className="panel-body">
                        <div className="panel-text">
                          <p>
                            Sure you can. You can call us and book your bus
                            ticket over the phone or approaching our offices
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="tj-tab-17">
                      <div className="panel-title">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#tj-tab-collapse17"
                          aria-expanded="false"
                          aria-controls="tj-tab-collapse17"
                          className="collapsed"
                        >
                          17. What are the advantages you would get renting a
                          car from us?
                        </a>
                      </div>
                    </div>
                    <div
                      id="tj-tab-collapse17"
                      className="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="tj-tab-17"
                    >
                      <div className="panel-body">
                        <div className="panel-text">
                          <p>
                            We provide quality rental services at competitive
                            rates. We have friendly and efficient staff to help.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="tj-tab-18">
                      <div className="panel-title">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#tj-tab-collapse18"
                          aria-expanded="false"
                          aria-controls="tj-tab-collapse18"
                          className="collapsed"
                        >
                          18. How do we ensure your on-road safety?
                        </a>
                      </div>
                    </div>
                    <div
                      id="tj-tab-collapse18"
                      className="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="tj-tab-18"
                    >
                      <div className="panel-body">
                        <div className="panel-text">
                          <p>
                            For on-road safety, we provide cars which are clean,
                            well maintained cars and hire only courteous and
                            experienced chauffeurs well versed with the routes
                            of the trip.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default React.memo(FAQ);

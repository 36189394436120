/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router";

function Footer() {
  const navigate = useNavigate();

  const handleNavigateHome = () => {
    navigate("/");
    window.location.reload();
  };

  const handleNavigateFaq = () => {
    navigate("/faq");
    window.location.reload();
  };

  const handleNavigateTerms = () => {
    navigate("/terms");
    window.location.reload();
  };

  return (
    <div>
      <section className="tj-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="about-widget widget">
                <h3>About ShivaCab</h3>
                <p>
                  Shiva Cabs is one of the best and eminent Car and Coach
                  Providers in Bangalore, Karnataka. We offer our customers
                  excellent Car Rental Services, Bus Rental Services, Tempo
                  Traveler Services to our customers, with the goal that they
                  travel with comfort and besides, they can explore the popular
                  location.
                </p>
                <ul className="fsocial-links">
                  <li>
                    <a href="http://www.facebook.com">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="http://www.twitter.com">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="http://www.linkedin.com">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a href="http://www.pinterest.com">
                      <i className="fab fa-pinterest-p"></i>
                    </a>
                  </li>
                  <li>
                    <a href="http://www.instagram.com">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2">
              <div className="links-widget widget">
                <h3>Explore Links</h3>
                <ul className="flinks-list">
                  <li>
                    <i className="far fa-folder"></i>
                    <a onClick={handleNavigateFaq}>Faq</a>
                  </li>
                  <li>
                    <i className="far fa-folder"></i>
                    <a href="">Cancellation</a>
                  </li>
                  <li>
                    <i className="far fa-folder"></i>
                    <a onClick={handleNavigateTerms}>Terms</a>
                  </li>
                  <li>
                    <i className="far fa-folder"></i>
                    <a href="">Privacy Policy</a>
                  </li>
                  <li>
                    <i className="far fa-folder"></i>
                    <a role="button" onClick={handleNavigateHome}>
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="recent-tweets widget">
                <h3>Recent Tweets</h3>
                <div className="tj-tweets"></div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="contact-info widget">
                <h3>Contact Info</h3>
                <ul className="contact-box">
                  <li>
                    <i className="fas fa-home" aria-hidden="true"></i>
                    #38/2, Market Road Gandhi Bazar, Near A2B Basavanagudi,
                    Bangalore, Karnataka - 560004 India
                  </li>
                  <li>
                    <i className="far fa-envelope-open"></i>
                    <a href="mailto:bookings@shivacabs.in">
                      bookings@shivacabs.in
                    </a>
                  </li>
                  <li>
                    <i className="fas fa-phone-square"></i>
                    +91 88841 50555
                  </li>
                  <li>
                    <i className="fas fa-globe-asia"></i>
                    <a href="https://shivacabs.in/" target="_blank">
                      shivacabs.in
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tj-copyright">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <p>
                &copy; Copyrights 2024{" "}
                <a role="button" onClick={handleNavigateHome}>
                  Shiva Cab
                </a>
                . All Rights Reserved.
              </p>
            </div>
            <div className="col-md-6 col-sm-6">
              <ul className="payment-icons">
                <li>
                  <i className="fab fa-cc-visa"></i>
                </li>
                <li>
                  <i className="fab fa-cc-mastercard"></i>
                </li>
                <li>
                  <i className="fab fa-cc-paypal"></i>
                </li>
                <li>
                  <i className="fab fa-cc-discover"></i>
                </li>
                <li>
                  <i className="fab fa-cc-jcb"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default React.memo(Footer);

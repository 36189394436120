import "./booking.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Layout from "../../layout";
import CabSelected from "../../components/cabSelected";
import TermsAndConditions from "../../components/termsConditions";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Box,
  Button,
  Fade,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const defaultErrorState = {
  name: false,
  email: false,
  phone: false,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 400,
  bgcolor: "background.paper",
  border: "1px solid #ececec",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

function validatePhone(phone) {
  const phoneRegex = /^\+91\s?\d{5}\s?\d{5}$/;

  return phoneRegex.test(phone);
}

const BookingConfirmedModal = ({
  open,
  handleClose,
  paymentId,
  paymentStatus,
}) => {
  const failed = paymentStatus === "Failed";
  return (
    <Modal open={open} onClose={handleClose}>
      <Fade in={open}>
        <Box sx={style}>
          <Typography variant="h5" textAlign={"center"}>
            {failed ? "Payment Failed" : "Booking Confirmed"}
          </Typography>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            padding={2}
            gutterBottom
          >
            <Grid
              item
              border={failed ? "2px solid red" : "2px solid green"}
              borderRadius={"50%"}
              height={50}
              width={50}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {failed ? (
                <CloseIcon fontSize={"large"} color="warning" padding={2} />
              ) : (
                <CheckIcon fontSize={"large"} color="success" padding={2} />
              )}
            </Grid>
          </Grid>

          {!failed && (
            <Typography textAlign={"center"}>
              Your booking of payment ID <b>{paymentId}</b> has been
              successfully confirmed. Thank you for choosing our service.
            </Typography>
          )}
          <Grid sx={{ textAlign: "center" }} mt={2}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#dd3751",
                "&:hover": {
                  backgroundColor: "#b32c40",
                },
              }}
              onClick={handleClose}
            >
              Go to Home
            </Button>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};

const Booking = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const totalPrice = searchParams.get("total");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [loading, setLoading] = useState(false);

  const paymentStatus = searchParams.get("payment_status");

  const [error, setError] = useState(defaultErrorState);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    navigate("/");
  };

  const handlePhoneChange = (newValue) => {
    setPhone(newValue);
  };

  const handleError = () => {
    setError(() => ({
      name: !name,
      email: !validateEmail(email),
      phone: !validatePhone(phone),
    }));

    return !!name && !!validateEmail(email) && !!validatePhone(phone);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (handleError()) {
      try {
        const createUserBooking = await axios
          .post(
            `${process.env.REACT_APP_PUBLIC_URL}/api/shiva-cab-requests/create-user-booking/`,
            {
              email: email,
              mobile_name: name,
              booking: {
                user_name: name,
                mobile_number: phone,
                destination: searchParams.get("to") ?? "",
                origin: searchParams.get("from") ?? "",
                journey_date: new Date(searchParams.get("startDate")).toISOString() ?? "",
                trip_completed_date: new Date(searchParams.get("endDate")).toISOString() ?? "",

                advance_booking_amount: parseFloat(totalPrice) * 0.1 ?? 0,
                Total_booking_amount: parseFloat(totalPrice) ?? 0,
                journey_type: searchParams.get("type") ?? "",

                // total_kilometer:
                // parseInt(searchParams.get("distance")) / 1000 ?? 0,
                cab_type: 1,
                booking_type: 1,
              },
            }
          )
          .then((res) => {
            return res.data;
          });

        searchParams.set(
          "booking_id",
          createUserBooking.booking.cab_booking_id
        );
        const redirectUrl = new URL(
          `/booking?${searchParams.toString()}`,
          process.env.REACT_APP_PUBLIC_URL_FRONTEND
        );

        await axios
          .post(
            `${process.env.REACT_APP_PUBLIC_URL}/api/shiva-cab-requests/make-payment/${createUserBooking.booking.cab_booking_id}/`,
            {
              booking_id: createUserBooking.booking.cab_booking_id,
              redirect_url: redirectUrl,
            }
          )
          .then((res) => {
            const payment_gateway = res.data;
            window.location.href = payment_gateway.payment_url;
          });
      } catch (error) {
        console.log(error);
      }
    }
    setLoading(false);
  };


  const handleCompletePayment = async () => {
    setLoading(true);
    try {
      await axios.get(
        `${
          process.env.REACT_APP_PUBLIC_URL
        }/api/shiva-cab-requests/payment-status/?${searchParams.toString()}`
      );
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const innerWidthMd = useMediaQuery(theme.breakpoints.up("md"));
  const innerWidthLg = useMediaQuery(theme.breakpoints.up("lg"));
  useEffect(() => {
    if (paymentStatus === "Credit") {
      handleCompletePayment();
      handleOpen();
    } else if (paymentStatus === "Failed") {
      handleOpen();
    }
  }, [paymentStatus]);
  return (
    <Layout>
      <Modal
        open={loading}
        height="100vh"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <span class="loader"></span>
      </Modal>
      <div className="container">
        <Grid
          container
          padding={2}
          marginTop={2}
          sx={{
            backgroundColor: "#fff",
            border: "1px solid #e0e0e0",
            borderRadius: 2,
          }}
          columnGap={4}
          rowGap={2}
        >
          <Grid item>
            <Typography>
              <b>From: </b>
            </Typography>
            <Typography>{searchParams.get("from")}</Typography>
          </Grid>
          <Grid item>
            <Typography>
              <b>To: </b>
            </Typography>
            <Typography>{searchParams.get("to")}</Typography>
          </Grid>
          <Grid item>
            <Typography>
              <b>Pickup Date: </b>
            </Typography>
            <Typography>
              {new Date(searchParams.get("startDate")).toLocaleDateString(
                "en-UK",
                { dateStyle: "full" }
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <b>Pickup Time: </b>
            </Typography>
            <Typography>{searchParams.get("pickupTime")}</Typography>
          </Grid>
          <Grid item>
            <Typography>
              <b>Drop Date: </b>
            </Typography>
            <Typography>
              {new Date(searchParams.get("endDate")).toLocaleDateString(
                "en-UK",
                { dateStyle: "full" }
              )}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <CabSelected />
      <div className="container">
        <BookingConfirmedModal
          open={open}
          handleClose={handleClose}
          paymentId={searchParams.get("payment_id")}
          paymentStatus={paymentStatus}
        />
        <Grid rowGap={2} container justifyContent={"space-between"}>
          <Grid
            item
            md={7.5}
            xs={12}
            padding={2}
            sx={{
              backgroundColor: "#fff",
              border: "1px solid #e0e0e0",
              borderRadius: 2,
            }}
          >
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Trip details:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <form>
                <Grid container rowGap={2} justifyContent={"space-between"}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" gutterBottom>
                      Confirm Traveler information
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={5.8}>
                    <Typography variant="subtitle1" color={"slategray"}>
                      Name
                    </Typography>
                    <TextField
                      required
                      error={error.name}
                      variant="outlined"
                      placeholder="Enter your full name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      fullWidth
                      size={"small"}
                      type="text"
                    />
                    <Typography
                      variant="caption"
                      color={"error"}
                      display={error.name ? "block" : "none"}
                    >
                      Enter a name
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    columnGap={1}
                    alignItems={"center"}
                    xs={12}
                    md={5.8}
                  >
                    <Typography variant="subtitle1" color={"slategray"}>
                      Email Id
                    </Typography>
                    {innerWidthMd && innerWidthLg && (
                      <Typography variant="caption">
                        (Your booking confirmation will be sent here)
                      </Typography>
                    )}
                    <TextField
                      required
                      error={error.email}
                      variant="outlined"
                      placeholder="Enter Email ID"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      fullWidth
                      size={"small"}
                      type="email"
                    />
                    <Typography
                      variant="caption"
                      color={"error"}
                      display={error.email ? "block" : "none"}
                    >
                      Enter a valid email address
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={5.8}>
                    <Typography variant="subtitle1" color={"slategray"}>
                      Contact Number
                    </Typography>
                    <MuiTelInput
                      required
                      error={error.phone}
                      defaultCountry={"IN"}
                      value={phone}
                      onChange={handlePhoneChange}
                      placeholder="Enter your mobile number"
                      size="small"
                      fullWidth
                    />
                    <Typography
                      variant="caption"
                      color={"error"}
                      display={error.phone ? "block" : "none"}
                    >
                      Enter a 10 digit number
                    </Typography>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
          <Grid
            item
            md={4.4}
            xs={12}
            padding={2}
            sx={{
              backgroundColor: "#fff",
              border: "1px solid #e0e0e0",
              borderRadius: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Total Amount:
              </Typography>
              <Typography variant="h5" gutterBottom>
                ₹{totalPrice}
              </Typography>
              <Typography variant="caption">(Inclusive of GST)</Typography>
            </Grid>
            <Grid item>
              <RadioGroup defaultValue="full-payment">
                <FormControlLabel
                  value={"part-payment"}
                  control={<Radio />}
                  label={`Make part payment of 10% now (₹${(
                    totalPrice * 0.1
                  ).toFixed(1)})`}
                />
                <FormControlLabel
                  value={"full-payment"}
                  control={<Radio />}
                  label="Make full payment now"
                />
                {/* <FormControlLabel
									value={"pay-later"}
									control={<Radio />}
									label="Pay Later"
								/> */}
              </RadioGroup>
            </Grid>
            <Button
              variant="contained"
              color="success"
              size="large"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
        <TermsAndConditions />
      </div>
    </Layout>
  );
};

export default Booking;

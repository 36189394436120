/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Layout from "../../layout";

import SearchBar from "../../components/searchBar";
import "./home.css";

function Home() {
	return (
		<Layout>
			<section className="tj-banner-form">
				<div className="container">
					<div className="row">
						<div
							style={{
								position: "relative",
								zIndex: 999,
							}}
						>
							<SearchBar />
						</div>
					</div>
				</div>
			</section>

			<section className="tj-offers">
				<div className="row">
					<div className="col-md-3 col-sm-6">
						<div className="offer-box">
							<img
								src="images/offer-icon1.png"
								alt="offer"
							/>
							<div className="offer-info">
								<h4>Best Price Guaranteed</h4>
								<p>
									Try to find a better deal than us, and get Rewarded. We are
									providing the best in class cabs at affordable prices.
								</p>
							</div>
						</div>
					</div>

					<div className="col-md-3 col-sm-6">
						<div className="offer-box">
							<img
								src="images/offer-icon2.png"
								alt=""
							/>
							<div className="offer-info">
								<h4>24/7 Customer Care</h4>
								<p>
									<a
										href="tel:91-88841-50555"
										target="_blank"
									>
										+91-88841-50555
									</a>
								</p>
							</div>
						</div>
					</div>

					<div className="col-md-3 col-sm-6">
						<div className="offer-box">
							<img
								src="images/offer-icon3.png"
								alt=""
							/>
							<div className="offer-info">
								<h4>Trust & Safety</h4>
								<p>
									We only provide the cabs which are thourougly tested and
									having immaculate condition.
								</p>
							</div>
						</div>
					</div>

					<div className="col-md-3 col-sm-6">
						<div className="offer-box">
							<img
								src="images/offer-icon4.png"
								alt=""
							/>
							<div className="offer-info">
								<h4>Easy Bookings</h4>
								<p>
									<a
										href="https://wa.me/+918884150555"
										target="_blank"
										rel="noreferrer"
									>
										WhatsApp
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="fleet-carousel">
				<div className="col-md-12 col-sm-12">
					<div className="tj-heading-style">
						<h3>Our Car Fleet</h3>
					</div>
				</div>
				<div className="carousel-outer">
					<div
						className="cab-carousel"
						id="cab-carousel"
					>
						<div className="fleet-item">
							<img
								src="images/tata-indica-four-seater.png"
								alt="tata-indica"
								loading="lazy"
							/>
							<div className="fleet-inner">
								<h4>Tata Indica</h4>
								<ul>
									<li>
										<i className="fas fa-taxi"></i>luxury
									</li>
									<li>
										<i className="fas fa-user-circle"></i>4 Passengers
									</li>
									<li>
										<i className="fas fa-tachometer-alt"></i>5.6/100 MPG
									</li>
								</ul>
								<strong className="price"> </strong>
								<a href="#booking-form-div">
									Book Now{" "}
									<i
										className="fa fa-arrow-circle-right"
										aria-hidden="true"
									></i>
								</a>
							</div>
						</div>
						<div className="fleet-item">
							<img
								src="images/innova-hycross_prev_ui.png"
								alt=""
							/>
							<div className="fleet-inner">
								<h4>Innova Hycross</h4>
								<ul>
									<li>
										<i className="fas fa-taxi"></i>luxury
									</li>
									<li>
										<i className="fas fa-user-circle"></i>7 Passengers
									</li>
									<li>
										<i className="fas fa-tachometer-alt"></i>7.6/100 MPG
									</li>
								</ul>
								<strong className="price"> </strong>
								<a href="#booking-form-div">
									Book Now{" "}
									<i
										className="fa fa-arrow-circle-right"
										aria-hidden="true"
									></i>
								</a>
							</div>
						</div>
						<div className="fleet-item">
							<img
								src="images/visigo-anadolu-isuzu.png"
								alt=""
							/>
							<div className="fleet-inner">
								<h4>Swaraj Mazda Mini</h4>
								<ul>
									<li>
										<i className="fas fa-taxi"></i>luxury
									</li>
									<li>
										<i className="fas fa-user-circle"></i>33 Passengers
									</li>
									<li>
										<i className="fas fa-tachometer-alt"></i>5.6/100 MPG
									</li>
								</ul>
								<strong className="price"> </strong>
								<a href="#booking-form-div">
									Book Now{" "}
									<i
										className="fa fa-arrow-circle-right"
										aria-hidden="true"
									></i>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="tj-reviews">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="tj-heading-style">
								<h3>Testimonials</h3>
							</div>
						</div>
						<div className="col-md-12 col-sm-12">
							<div
								id="testimonial-slider"
								className="reviews-slider"
							>
								<div className="review-item">
									<figure className="img-box">
										<img
											src="images/testimonal-avatar.png"
											alt="testimonal-image-one"
											className="testimonal-image"
										/>
									</figure>
									<div className="review-info">
										<strong>Aniket Bharti</strong>
										<div
											style={{
												display: "flex",
												justifyContent: "center",
											}}
										>
											<span className="icon-star-empty icomoon rating"></span>
											<span className="icon-star-empty icomoon rating"></span>
											<span className="icon-star-empty icomoon rating"></span>
											<span className="icon-star-empty icomoon rating"></span>
											<span className="icon-star-empty icomoon rating"></span>
										</div>
										<div className="review-quote"></div>
										<p>
											Shiva Cabs provided excellent service during our trip to
											Mysore. Nishanth was a very skilled, joyful, and
											experienced driver. We really enjoyed it.
										</p>
									</div>
								</div>

								<div className="review-item">
									<figure className="img-box">
										<img
											src="images/testimonal-avatar.png"
											alt="testimonal-image-two"
											className="testimonal-image"
										/>
									</figure>
									<div className="review-info">
										<strong>Vatsel Patel</strong>
										<div
											style={{
												display: "flex",
												justifyContent: "center",
											}}
										>
											<span className="icon-star-empty icomoon rating"></span>
											<span className="icon-star-empty icomoon rating"></span>
											<span className="icon-star-empty icomoon rating"></span>
											<span className="icon-star-empty icomoon rating"></span>
											<span className="icon-star-empty icomoon rating"></span>
										</div>
										<div className="review-quote">
											<p>
												It was our first attempt to book cab service online. But
												we were not at all disappointed with service from Shiva
												Cabs. We will definetily recommend Shiva Cabs to all.
											</p>
										</div>
									</div>
								</div>

								<div className="review-item">
									<figure className="img-box">
										<img
											src="images/testimonal-avatar.png"
											alt="testimonal-image-three"
											className="testimonal-image"
										/>
									</figure>
									<div className="review-info">
										<strong>Vinit Kulkarani</strong>
										<div
											style={{
												display: "flex",
												justifyContent: "center",
											}}
										>
											<span className="icon-star-empty icomoon rating"></span>
											<span className="icon-star-empty icomoon rating"></span>
											<span className="icon-star-empty icomoon rating"></span>
											<span className="icon-star-empty icomoon rating"></span>
											<span className="icon-star-empty icomoon"></span>
										</div>
										<div className="review-quote">
											<p>
												Our family members (including senior citizens) had a
												Darmastala trip with Shiva travels.The driver Me Pradeep
												was very safe driver and family friendly.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="tj-cal-to-action2">
				<div className="container">
					<div className="row">
						<div className="col-md-9 col-sm-9">
							<div className="cta-tagline">
								<h2>Incredible Destinations at Incredible Deals</h2>
							</div>
						</div>

						<div className="col-md-3 col-sm-3">
							<div className="cta-btn">
								<a href="#booking-form-div">
									Book Now{" "}
									<i
										className="fa fa-arrow-circle-right"
										aria-hidden="true"
									></i>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}

export default React.memo(Home);

/* eslint-disable no-useless-concat */
import * as React from "react";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export default function Notify({ open, message, duration, status, onClose }) {
  return (
    <Box sx={{ width: "100%" }}>
      <Snackbar
        sx={{ zIndex: 1400 }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        onClose={onClose}
        autoHideDuration={duration}
        key={"top" + "center"}
      >
        <Alert
          onClose={onClose}
          severity={status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          <span style={{ fontSize: "14px", color: "white" }}>{message}</span>
        </Alert>
      </Snackbar>
    </Box>
  );
}

import "./cabSelected.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Chip, Grid, Typography } from "@mui/material";

function CabSelected() {
	const [cab, setCab] = useState({});
	const [searchParams] = useSearchParams();

	const id = searchParams.get("cabId");
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_PUBLIC_URL}/api/shiva-cab-requests/cabtypes/${id}/`)
			.then((res) => {
				setCab(res.data);
			})
			.catch((error) => {
				console.log(error.message);
			});
	}, [id]);

	return (
		<div className="container">
			{!!cab.id && (
				<Grid
					item
					container
					xs={12}
					padding={2}
					marginBlock={2}
					border={"1px solid #e0e0e0"}
					backgroundColor={"#fff"}
					borderRadius={2}
				>
					<Grid
						item
						lg={3}
					>
						<img
							src={cab.cab_image_url}
							alt="car"
							width={"100%"}
						/>
					</Grid>
					<Grid
						item
						paddingInline={2}
						lg={8}
						sx={{
							width: "100%",
						}}
					>
						<Grid
							item
							marginBottom={2}
						>
							<Typography
								variant="h4"
								sx={{ fontWeight: "500" }}
								gutterBottom
							>
								{cab.vehicle_name}
							</Typography>
							<Grid
								item
								sx={{
									display: "flex",
									gap: 1,
									alignItems: "center",
									overflowX: "auto",
								}}
							>
								<Chip
									label={
										<Typography variant="body2">
											{cab.air_condition ? "AC" : "Non AC"}
										</Typography>
									}
									variant="outlined"
									clickable
								/>
								<Chip
									label={
										<Typography variant="body2">{`${cab.seats} Seats`}</Typography>
									}
									variant="outlined"
									clickable
								/>
								<Chip
									label={
										<Typography variant="body2">
											Boot space: {cab.cab_boot_space}
										</Typography>
									}
									variant="outlined"
									clickable
								/>
								{/* TODO: Need cab price details */}
								<Chip
									label={
										<Typography variant="body2">{`${cab.id} kms included`}</Typography>
									}
									variant="outlined"
									clickable
								/>
							</Grid>
						</Grid>
						<Grid
							item
							container
						>
							<Grid
								item
								container
								xs={12}
							>
								<Grid
									item
									xs={6}
								>
									<Typography variant="subtitle2">Extra km fare:</Typography>
								</Grid>
								<Grid
									item
									xs={6}
								>
									{/* TODO: Need cab price details */}

									<Typography variant="body2">
										{`₹${cab.id}/km after ${cab.id} kms`}
									</Typography>
								</Grid>
							</Grid>
							<Grid
								item
								container
								xs={12}
							>
								<Grid
									item
									xs={6}
								>
									<Typography variant="subtitle2">Fuel Type:</Typography>
								</Grid>
								<Grid
									item
									xs={6}
								>
									<Typography variant="body2">{cab.fuel_type}</Typography>
								</Grid>
							</Grid>
							<Grid
								item
								container
								xs={12}
							>
								<Grid
									item
									xs={6}
								>
									<Typography variant="subtitle2">Cancellation:</Typography>
								</Grid>
								<Grid
									item
									xs={6}
								>
									<Typography variant="body2">
										Free till 1 hour of departure
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
		</div>
	);
}

export default CabSelected;

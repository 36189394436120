/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { useNavigate } from "react-router";
import { AppContext } from "../../context";
import { useSearchParams } from "react-router-dom";

import { Chip, Grid, Modal, Typography } from "@mui/material";

import Layout from "../../layout";
import CabList from "../../components/cabList";
import SearchBar from "../../components/searchBar";
import CareContainer from "../../components/careContainer";

function Fleet() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { distance } = useContext(AppContext);

	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const to = searchParams.get("to");
	const from = searchParams.get("from");
	const startDate = searchParams.get("startDate");
	const endDate = searchParams.get("endDate");
	const pickupTime = searchParams.get("pickupTime");
	const distanceSearchParam = searchParams.get("distance");

	const handleNavigateHome = () => {
		navigate("/");
		window.location.reload();
	};
	return (
		<Layout>
			<div className="tj-breadcrumb">
				<div className="container">
					<ul className="breadcrumb-list">
						<li>
							<a
								role="button"
								onClick={handleNavigateHome}
							>
								Home
							</a>
						</li>
						<li className="active">Car Fleet</li>
					</ul>
				</div>
			</div>

			<section className="car-fleet">
				<div className="container">
					<Grid container>
						<Modal
							open={open}
							onClose={handleClose}
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<div className="container">
								<SearchBar handleClose={handleClose} />
							</div>
						</Modal>

						<Grid
							item
							container
							spacing={2}
							alignItems={"center"}
						>
							<Grid
								item
								onClick={handleOpen}
							>
								<button>Modify</button>
							</Grid>
							{to && from && startDate && endDate && pickupTime && (
								<Grid
									item
									container
									spacing={1}
									paddingInline={2}
									alignItems={"center"}
									sx={{
										overflowX: "scroll",
										flexWrap: "nowrap",
									}}
								>
									<Grid item>
										<Chip
											label={
												<Typography variant="body2">
													{from} to{" "}
													{to.length > 12 ? to.slice(0, 12) + "..." : to}
												</Typography>
											}
											variant="outlined"
											clickable
										/>
									</Grid>
									<Grid item>
										<Chip
											label={
												<Typography variant="body2">
													{new Date(startDate).toLocaleDateString()} to{" "}
													{new Date(endDate).toLocaleDateString()}
												</Typography>
											}
											variant="outlined"
											clickable
										/>
									</Grid>
									<Grid item>
										<Chip
											label={
												<Typography variant="body2">
													Distance:{" "}
													{Math.round(
														((!distance ? distanceSearchParam : distance) * 2) /
															1000
													)}{" "}
													kms
												</Typography>
											}
											variant="outlined"
											clickable
										/>
									</Grid>
								</Grid>
							)}
						</Grid>
						<Grid
							item
							container
						>
							<CabList />
						</Grid>
					</Grid>
				</div>
			</section>
			<CareContainer />
		</Layout>
	);
}

export default React.memo(Fleet);

/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router";
import "./header.css";

function Header() {
	const navigate = useNavigate();

	const handleNavigateAboutus = () => {
		navigate("/about");
		window.location.reload();
	};
	const handleNavigateServices = () => {
		navigate("/services");
		window.location.reload();
	};

	// const handleNavigateBlog = () => {
	//   navigate("/blog");
	//   window.location.reload();
	// };

	const handleNavigateFleet = () => {
		navigate("/fleet");
		window.location.reload();
	};

	const handleNavigateContact = () => {
		navigate("/contact");
		window.location.reload();
	};

	const handleNavigateHome = () => {
		navigate("/");
		window.location.reload();
	};

	const handleNavigaFaq = () => {
		navigate("/faq");
		window.location.reload();
	};

	const handleRedirectWhatsapp = () => {
		const newTab = window.open("https://wa.me/+918884150555", "_blank");
		window.close();
		return () => {
			newTab.close();
		};
	};

	const handlePhoneCall = () => {
		const newTab = window.open("tel:91-88841-50555", "_blank");
		window.close();
		return () => {
			newTab.close();
		};
	};

	return (
		<header className="tj-header">
			<div className="container">
				<div className="row">
					<div className="col-md-3 col-sm-4 col-xs-12">
						<div className="tj-logo cursor-icon">
							<a onClick={handleNavigateHome}>
								<img
									src="images/shiva_cabs_logo.png"
									alt="logo"
									style={{ width: "40%", borderRadius: "50%" }}
								/>
							</a>
						</div>
					</div>
					<div className="col-md-3 col-sm-4 col-xs-12">
						<div
							className="info_box cursor-icon"
							onClick={handleRedirectWhatsapp}
						>
							<i className="fab fa-whatsapp"></i>
							<div className="info_text">
								<span className="info_title">Quick Booking</span>
								<span>
									<a
										href="mailto:bookings@shivacabs.in"
										target="_blank"
										rel="noreferrer"
									>
										Whatsapp
									</a>
								</span>
							</div>
						</div>
					</div>
					<div className="col-md-3 col-sm-4 col-xs-12">
						<div className="info_box">
							<i className="fa fa-envelope"></i>
							<div className="info_text">
								<span className="info_title">Email Us</span>
								<span>
									<a
										href="mailto:bookings@shivacabs.in"
										target="_blank"
										rel="noreferrer"
									>
										bookings@shivacabs.in
									</a>
								</span>
							</div>
						</div>
					</div>
					<div className="col-md-3 col-xs-12">
						<div className="phone_info">
							<div className="phone_icon cursor-icon">
								<i
									className="fas fa-phone-volume"
									onClick={handlePhoneCall}
								></i>
							</div>
							<div className="phone_text">
								<span>
									<a
										href="tel:91-88841-50555"
										target="_blank"
									>
										+91-88841-50555
									</a>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="tj-nav-row">
				<div className="container">
					<div className="row">
						<div className="tj-nav-holder">
							<nav className="navbar navbar-default">
								<div className="navbar-header">
									<button
										type="button"
										className="navbar-toggle collapsed"
										data-toggle="collapse"
										data-target="#tj-navbar-collapse"
										aria-expanded="false"
									>
										<span className="sr-only">Menu</span>
										<span className="icon-bar"></span>
										<span className="icon-bar"></span>
										<span className="icon-bar"></span>
									</button>
								</div>
								<div
									className="collapse navbar-collapse"
									id="tj-navbar-collapse"
								>
									<ul className="nav navbar-nav">
										<li>
											<a
												role="button"
												onClick={handleNavigateHome}
											>
												Home
											</a>
										</li>
										<li>
											<a
												role="button"
												onClick={handleNavigateAboutus}
											>
												About us
											</a>
										</li>
										<li>
											<a
												role="button"
												onClick={handleNavigateServices}
											>
												Services
											</a>
										</li>
										<li>
											<a
												role="button"
												onClick={handleNavigateFleet}
											>
												Our Fleets
											</a>
										</li>
										<li>
											<a
												role="button"
												onClick={handleNavigaFaq}
											>
												Faq
											</a>
										</li>
										<li>
											<a
												role="button"
												onClick={handleNavigateContact}
											>
												Contact us
											</a>
										</li>
									</ul>
								</div>
							</nav>
							<div className="book_btn">
								<a
									role="button"
									onClick={handleNavigateHome}
								>
									Book Now
									<i
										className="fa fa-arrow-circle-right"
										aria-hidden="true"
									></i>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
}

export default React.memo(Header);

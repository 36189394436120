import { Grid, Typography } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const termsAndConditions = [
	{
		title: "Bata Charges",
		description: "Usual Driver bata timings are from 6 AM to 10PM.",
	},
	{
		title: "Bata Charges Extra",
		description:
			"If the driver drives after 10PM and before 6AM then there would be an extra driver bata.",
	},
	{
		title: "Driver Allowance",
		description: "Driver allowance will take care of his food and shelter.",
	},
	{
		title: "Driver Allowance Extra",
		description:
			"Driver allowance will be extra if the driver drives between 10 PM and 6 AM.",
	},
	{
		title: "Day Charges",
		description:
			"One day means one calendar day (from midnight 12 to midnight 12).",
	},
	{
		title: "Local Usage",
		description: "Local usage is not allowed in Bangalore.",
	},
	{
		title: "Total Price Calculations",
		description:
			"The final amount will depend on actual traveled distance and days.",
	},
	{
		title: "GST",
		description: "There is NO GST as of now.",
	},
	{
		title: "Extras",
		description:
			"State Permit, Parking and Toll charges will be extra, and advised to get the information from us.",
	},
	{
		title: "Hilly Regions",
		description: "AC will be switched off in hilly areas.",
	},
	{
		title: "Cancellation Policy",
		description:
			"FULL refund on cancellation before 24 hours. NO refund on cancellation before 12 hours.",
	},
];

export default function TermsAndConditions({ handleClose }) {
	return (
		<Grid
			container
			padding={2}
			marginBlock={2}
			sx={{
				backgroundColor: "#fff",
				border: "1px solid #e0e0e0",
				borderRadius: 2,
			}}
		>
			<Grid
				container
				alignItems={"center"}
				justifyContent={"space-between"}
			>
				<Grid item>
					<Typography
						gutterBottom
						variant="h6"
					>
						Terms and Conditions:
					</Typography>
				</Grid>
				{handleClose && (
					<CloseRoundedIcon
						onClick={handleClose}
						sx={{ cursor: "pointer" }}
					/>
				)}
			</Grid>
			<Grid
				item
				xs={12}
			>
				<ul>
					{termsAndConditions.map(({ title, description }, id) => (
						<li key={id}>
							<Typography
								paddingBlock={1}
								variant="body1"
							>
								<strong>{title}:</strong> {description}
							</Typography>
						</li>
					))}
				</ul>
			</Grid>
		</Grid>
	);
}

import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import App from "./App";
import { ContextProvider } from "./context";
import reportWebVitals from "./reportWebVitals";

import { ThemeProvider, createTheme } from "@mui/material/styles";

const materialTheme = createTheme({
	typography: {
		// In Chinese and Japanese the characters are usually larger,
		// so a smaller fontsize may be appropriate.
		fontSize: 12,
		// Tell Material UI what the font-size on the html element is.
		htmlFontSize: 10,
	},
});

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={materialTheme}>
			<ContextProvider>
				<App />
			</ContextProvider>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
// import { FloatingWhatsApp } from "react-floating-whatsapp";

import "./whatsApp.css";

function WhatsappBooking() {
  return (
    <a
      href="https://wa.me/+918884150555"
      target="_blank"
      rel="noreferrer"
      className="whatsapp-float"
    >
      <i className="fab fa-whatsapp"></i>
    </a>
    // <FloatingWhatsApp
    //   phoneNumb="+918884150555"
    //   accountName="Shiva Cabs"
    //   avatar="images/shiva_cabs_logo.jpeg"
    //   statusMessage="Typically replies within an hour"
    //   chatMessage="Hello! How can we help you today?"
    //   placeholder="Type your message here..."
    //   darkMode={false}
    //   allowClickAway
    //   notification
    //   notificationDelay={60000}
    // />
  );
}

export default React.memo(WhatsappBooking);

/* eslint-disable react/jsx-no-target-blank */
import React from "react";

function CareContainer() {
  return (
    <section className="tj-cal-to-action">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-4">
            <div className="cta-box">
              <img src="images/cta-icon1.png" alt="" />
              <div className="cta-text">
                <strong>Best Price Guaranteed</strong>
                <p>
                  Try to find a better deal than us, and get Rewarded. We are
                  providing the best in class cabs at affordable prices.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-4">
            <div className="cta-box">
              <img src="images/cta-icon2.png" alt="" />
              <div className="cta-text">
                <strong>24/7 Customer Care</strong>
                <p>
                  <a
                    href="tel:91-88841-50555"
                    target="_blank"
                    style={{ color: "white" }}
                  >
                    +91-88841-50555
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-4">
            <div className="cta-box">
              <img src="images/cta-icon3.png" alt="" />
              <div className="cta-text">
                <strong>Easy Bookings</strong>
                <p>
                  <a
                    href="https://wa.me/+918884150555"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "white" }}
                  >
                    whatsApp
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default React.memo(CareContainer);

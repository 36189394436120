import axiosInstance from "./axiosInstance";
import { endpoints } from "./endpoints";

export const submitCabRequestForm = async (payload) => {
  try {
    const response = await axiosInstance.post(endpoints.submitRequest, payload);
    if (response.status === 201) {
      return response.status;
    }
  } catch (error) {
    return 404;
  }
};

export const getAllCabs = async () => {
  try {
    const response = await axiosInstance.get(endpoints.cabTypes);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("error", error);
    return error;
  }
};

import React from "react";
import { createContext } from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import FAQ from "./pages/faq";
import Home from "./pages/home";
import Blog from "./pages/blog";
import Terms from "./pages/terms";
import About from "./pages/about";
import Policy from "./pages/policy";
import Fleet from "./pages/ourFleet";
import Booking from "./pages/booking";
import Contact from "./pages/contact";
import Services from "./pages/services";
import NotFound from "./pages/notFound";

import ScrollToTop from "./components/scrollTop";
import ErrorBoundary from "./components/errorBoundary";

function App() {
	return (
		<ErrorBoundary>
			<Router>
				<ScrollToTop />
				<div>
					<Routes>
						<Route
							path="/"
							element={<Home />}
						/>
						<Route
							path="/about"
							element={<About />}
						/>
						<Route
							path="/booking"
							element={<Booking />}
						/>
						<Route
							path="/contact"
							element={<Contact />}
						/>
						<Route
							path="/blog"
							element={<Blog />}
						/>
						<Route
							path="/services"
							element={<Services />}
						/>
						<Route
							path="/fleet"
							element={<Fleet />}
						/>
						<Route
							path="/policy"
							element={<Policy />}
						/>
						<Route
							path="/faq"
							element={<FAQ />}
						/>
						<Route
							path="/terms"
							element={<Terms />}
						/>
						<Route
							path="*"
							element={<NotFound />}
						/>
					</Routes>
				</div>
			</Router>
		</ErrorBoundary>
	);
}

export default App;

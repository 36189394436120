export const getTotalAmount = (cab, distance, dayCount) => {
	const fare = cab?.cab_price_details;
	if (!fare) return "₹0";

	const farePerkm = Math.floor(fare.per_km_price * (distance / 1000)) * 2;

	const driverFarePerday = fare.driver_charges_per_day * dayCount;
	const minimumFarePerday = fare.minimum_price_per_day * dayCount;

	return `₹${farePerkm + driverFarePerday + minimumFarePerday}`;
};

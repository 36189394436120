/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router";
import Layout from "../../layout";
import CareContainer from "../../components/careContainer";
import "./contact.css";

function Contact() {
  const navigate = useNavigate();
  const handleNavigateHome = () => {
    navigate("/");
    window.location.reload();
  };
  return (
    <Layout>
      <div className="tj-breadcrumb">
        <div className="container">
          <ul className="breadcrumb-list">
            <li>
              <a role="button" onClick={handleNavigateHome}>
                Home
              </a>
            </li>
            <li className="active">Contactus</li>
          </ul>
        </div>
      </div>
      <section className="tj-contact-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="tj-heading-style">
                <h3>Contact Us</h3>
                <p className="contact-us-text">
                  Shiva Cabs is one of the proficient and renowned Car & Coach
                  Providers in Bangalore, Karnataka. We offer our clients
                  excellent Car Rental Services, Bus Rental Services, Tempo
                  Traveller Services to our clients, so that they travel
                  comfortably and moreover, they can easily explore the popular
                  hotspots of location.
                </p>
              </div>
            </div>
            <div className="col-md-8 col-sm-8">
              <div className="form-holder">
                <form
                  method="POST"
                  className="tj-contact-form"
                  id="contact-form"
                >
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <div className="inner-holder">
                        <label htmlFor="name">Name</label>
                        <input
                          placeholder="Enter Your Name"
                          name="name"
                          type="text"
                          id="name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 no-pad">
                      <div className="inner-holder">
                        <label htmlFor="email">Email</label>
                        <input
                          placeholder="Enter Your Email"
                          name="email"
                          type="email"
                          id="email"
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                      <div className="inner-holder">
                        <label htmlFor="subject">Subject</label>
                        <input
                          placeholder="Your Subject"
                          name="subject"
                          type="text"
                          id="subject"
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                      <div className="inner-holder">
                        <label htmlFor="message">Message</label>
                        <textarea
                          name="message"
                          placeholder="Your Message"
                          id="message"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                      <div className="inner-holder">
                        <button
                          className="btn-submit"
                          id="frm_submit_btn"
                          type="submit"
                        >
                          Send Message{" "}
                          <i
                            className="fa fa-arrow-circle-right"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="address-box">
                <div className="add-info">
                  <span className="icon-map icomoon"></span>
                  <p>
                    # 38/2, Market Road Gandhi Bazar,
                    <br /> Near A2B Basavanagudi,
                    <br /> Bangalore, Karnataka - 560004 India
                  </p>
                </div>
                <div className="add-info">
                  <span className="icon-phone icomoon"></span>
                  <p>
                    <a href="tel:91 88841 50555" target="_blank">
                      +91 88841 50555
                    </a>
                  </p>
                </div>
                <div className="add-info">
                  <span className="icon-mail-envelope-open icomoon"></span>
                  <p>
                    <a href="mailto:bookings@shivacabs.in">
                      bookings@shivacabs.in
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CareContainer/>
    </Layout>
  );
}

export default React.memo(Contact);

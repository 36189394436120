import "./carList.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
	Chip,
	Grid,
	IconButton,
	Modal,
	Popover,
	Skeleton,
	Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { getTotalAmount } from "../../utils";
import TermsAndConditions from "../termsConditions";

const PriceBreakdown = ({ cab, distance, dayCount }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handlePopoverClick = (event) => {
		if (!anchorEl) {
			setAnchorEl(event.currentTarget);
			return;
		}
		setAnchorEl(null);
	};

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	return (
		<>
			<IconButton
				aria-owns={open ? `mouse-over-popover-${cab.id}` : undefined}
				aria-haspopup="true"
				onClick={handlePopoverClick}
				onMouseEnter={handlePopoverOpen}
				onMouseLeave={handlePopoverClose}
			>
				<InfoIcon />
			</IconButton>
			<Popover
				id={`mouse-over-popover-${cab.id}`}
				sx={{
					pointerEvents: "none",
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Grid
					item
					container
					width={280}
					padding={2}
				>
					<Grid
						item
						xs={12}
					>
						<Typography
							variant="h6"
							gutterBottom
						>
							Price breakdown:
						</Typography>
					</Grid>
					<Grid
						item
						xs={9}
					>
						<Typography variant="subtitle2">Per day minimum cost:</Typography>
					</Grid>
					<Grid
						item
						xs={3}
					>
						<Typography variant="body2">
							₹{cab?.cab_price_details?.minimum_price_per_day ?? "NA"}
						</Typography>
					</Grid>
					<Grid
						item
						xs={9}
					>
						<Typography variant="subtitle2">Per day driver charges:</Typography>
					</Grid>
					<Grid
						item
						xs={3}
					>
						<Typography variant="body2">
							₹{cab?.cab_price_details?.driver_charges_per_day ?? "NA"}
						</Typography>
					</Grid>
					<Grid
						item
						xs={9}
					>
						<Typography variant="subtitle2">Per km charges:</Typography>
					</Grid>
					<Grid
						item
						xs={3}
					>
						<Typography variant="body2">
							₹{cab?.cab_price_details?.per_km_price ?? "NA"}
						</Typography>
					</Grid>
					<Grid
						item
						xs={9}
					>
						<Typography variant="subtitle2">Day count:</Typography>
					</Grid>
					<Grid
						item
						xs={3}
					>
						<Typography variant="body2">{dayCount} days</Typography>
					</Grid>

					<Grid
						item
						container
						marginTop={1}
					>
						<Grid
							item
							xs={9}
						>
							<Typography variant="subtitle1">Total:</Typography>
						</Grid>
						<Grid
							item
							xs={3}
						>
							<Typography variant="body1">
								{getTotalAmount(cab, distance, dayCount)}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Popover>
		</>
	);
};

const getDayCount = (date1, date2) => {
	const diffTime = Math.abs(date2 - date1);
	const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

	return diffDays;
};

const CabList = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const distance = searchParams.get("distance");
	const endDate = searchParams.get("endDate");
	const startDate = searchParams.get("startDate");

	const [cabList, setCabList] = useState([]);
	const [openModal, setOpenModal] = useState(false);

	const dayCount = getDayCount(new Date(endDate), new Date(startDate));

	const handleTermsAndConditions = () => {
		setOpenModal(!openModal);
	};

	const handleBooking = (id, totalPrice) => {
		searchParams.append("cabId", id);
		searchParams.append("total", totalPrice.split("₹")[1]);
		navigate(`/booking?${searchParams.toString()}`);
	};
	useEffect(() => {
		axios
			.get(
				`${process.env.REACT_APP_PUBLIC_URL}/api/shiva-cab-requests/cab-list/`
			)
			.then((res) => {
				console.log(res.data.map((cab) => cab.cab_price_details));
				setCabList(res.data);
			})
			.catch((error) => {
				console.log(error.message);
			});
	}, []);

	return (
		<>
			<Modal
				open={openModal}
				onClose={handleTermsAndConditions}
			>
				<div
					className="container"
					style={{
						height: "100%",
						display: "flex",
						alignItems: "center",
					}}
				>
					<Grid container>
						<Grid
							item
							xs={12}
						>
							<TermsAndConditions handleClose={handleTermsAndConditions} />
						</Grid>
					</Grid>
				</div>
			</Modal>
			<Grid
				container
				gap={2}
				paddingBlockStart={2}
			>
				{cabList.length ? (
					cabList.map((cab) => {
						const totalPrice = getTotalAmount(cab, distance, dayCount);
						return (
							<Grid
								key={cab.id}
								item
								container
								xs={12}
								padding={2}
								border={"1px solid #e0e0e0"}
								backgroundColor={"#fff"}
								borderRadius={2}
							>
								<Grid
									item
									lg={3}
								>
									<img
										src={cab?.cab_image_url}
										alt="car"
										width={"100%"}
									/>
								</Grid>
								<Grid
									item
									paddingInline={2}
									lg={7}
									sx={{
										width: "100%",
									}}
								>
									<Grid
										item
										marginBottom={2}
									>
										<Typography
											variant="h4"
											sx={{ fontWeight: "500" }}
											gutterBottom
										>
											{cab?.vehicle_name}
										</Typography>
										<Grid
											item
											sx={{
												display: "flex",
												gap: 1,
												alignItems: "center",
												overflowX: "auto",
											}}
										>
											<Chip
												label={
													<Typography variant="body2">
														{cab?.air_condition ? "AC" : "Non AC"}
													</Typography>
												}
												variant="outlined"
												clickable
											/>
											<Chip
												label={
													<Typography variant="body2">{`${cab?.seats} Seats`}</Typography>
												}
												variant="outlined"
												clickable
											/>
											<Chip
												label={
													<Typography variant="body2">
														Boot space: {cab?.cab_boot_space}
													</Typography>
												}
												variant="outlined"
												clickable
											/>
											<Chip
												label={
													<Typography variant="body2">{`${
														cab?.cab_price_details?.minimum_distance_inkm ??
														"NA"
													} kms included`}</Typography>
												}
												variant="outlined"
												clickable
											/>
										</Grid>
									</Grid>
									<Grid
										item
										container
									>
										<Grid
											item
											container
											xs={12}
										>
											<Grid
												item
												xs={6}
											>
												<Typography variant="subtitle2">
													Extra km fare:
												</Typography>
											</Grid>
											<Grid
												item
												xs={6}
											>
												<Typography variant="body2">
													{`₹${
														cab?.cab_price_details?.per_km_price ?? "NA"
													}/km after ${
														cab?.cab_price_details?.minimum_distance_inkm ??
														"NA"
													} kms`}
												</Typography>
											</Grid>
										</Grid>
										<Grid
											item
											container
											xs={12}
										>
											<Grid
												item
												xs={6}
											>
												<Typography variant="subtitle2">Fuel Type:</Typography>
											</Grid>
											<Grid
												item
												xs={6}
											>
												<Typography variant="body2">{cab.fuel_type}</Typography>
											</Grid>
										</Grid>
										<Grid
											item
											container
											xs={12}
										>
											<Grid
												item
												xs={6}
											>
												<Typography variant="subtitle2">
													Cancellation:
												</Typography>
											</Grid>
											<Grid
												item
												xs={6}
											>
												<Typography variant="body2">
													Free till 1 hour of departure
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid
									item
									container
									alignItems={"center"}
									lg={2}
									paddingBlock={2}
								>
									<Grid
										item
										container
										alignItems={"center"}
										justifyContent={"center"}
										xs={12}
									>
										<Grid
											item
											container
											gap={1}
											alignItems={"center"}
											justifyContent={"center"}
										>
											<Grid item>
												<Typography
													variant="h5"
													align="center"
												>
													{totalPrice}
												</Typography>
											</Grid>
											<Grid
												item
												sx={{ position: "relative" }}
											>
												<PriceBreakdown
													cab={cab}
													distance={distance}
													dayCount={dayCount}
												/>
											</Grid>
										</Grid>
										<Grid
											item
											container
											flexDirection={"column"}
											alignItems={"center"}
											justifyContent={"center"}
										>
											<Grid item>
												<button
													onClick={() => handleBooking(cab.id, totalPrice)}
												>
													Book Now
												</button>
											</Grid>
											<Grid item>
												<Typography
													variant="caption"
													onClick={handleTermsAndConditions}
													sx={{
														cursor: "pointer",
														color: "#dd3751",
														marginTop: 2,
													}}
												>
													Terms & Conditions
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						);
					})
				) : (
					<Grid container>
						<Grid container>
							<Skeleton
								height={200}
								width={"100%"}
								animation="wave"
							/>
						</Grid>
						<Grid container>
							<Skeleton
								height={200}
								width={"100%"}
								animation="wave"
							/>
						</Grid>
						<Grid container>
							<Skeleton
								height={200}
								width={"100%"}
								animation="wave"
							/>
						</Grid>
					</Grid>
				)}
			</Grid>
		</>
	);
};

export default CabList;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router";
import Layout from "../../layout";
import CareContainer from "../../components/careContainer";
import "./services.css";

function Services() {
  const navigate = useNavigate();
  const handleNavigateHome = () => {
    navigate("/");
    window.location.reload();
  };
  return (
    <Layout>
      <div className="tj-breadcrumb">
        <div className="container">
          <ul className="breadcrumb-list">
            <li>
              <a role="button" onClick={handleNavigateHome}>
                Home
              </a>
            </li>
            <li className="active">Our Services</li>
          </ul>
        </div>
      </div>

      <section className="cab-services">
        <div className="container">
          <div className="row">
            <div className="tj-heading-style">
              <h3>Our Services</h3>
              <p>
                Our cab rental service offers convenient, reliable
                transportation solutions tailored to your needs, ensuring
                comfortable and stress-free travel experiences.
              </p>
            </div>

            <div className="col-md-4 col-sm-4">
              <div className="cab-service-box">
                <figure className="service-thumb">
                  <img
                    src="images/local-car-rental.png"
                    alt="local-car"
                    className="service-car-images"
                    loading="lazy"
                  />
                </figure>

                <div className="service-desc">
                  <h4>Local Car Rentals</h4>
                  <p>
                    Convenient, affordable local car rentals for flexible
                    transportation needs.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-4">
              <div className="cab-service-box">
                <figure className="service-thumb">
                  <img
                    src="images/outstation-car-rental.png"
                    alt="outstation-car-rental"
                    className="service-car-images"
                    loading="lazy"
                  />
                </figure>

                <div className="service-desc">
                  <h4>Outstation Taxi</h4>
                  <p>
                    Reliable outstation taxis for seamless long-distance travel
                    experiences.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-4">
              <div className="cab-service-box">
                <figure className="service-thumb">
                  <img
                    src="images/one-way-cab.png"
                    alt="one-way-cab"
                    className="service-car-images"
                    loading="lazy"
                  />
                </figure>

                <div className="service-desc">
                  <h4>One way cabs</h4>
                  <p>
                    Flexible one-way cab services for hassle-free, convenient
                    single-direction travel.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-4">
              <div className="cab-service-box">
                <figure className="service-thumb">
                  <img
                    src="images/office-car-rental.png"
                    alt="office-car-rental"
                    className="service-car-images"
                    loading="lazy"
                  />
                </figure>

                <div className="service-desc">
                  <h4>Corporate Car Rental</h4>
                  <p>
                    Efficient corporate car rental services for business travel
                    convenience.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-4">
              <div className="cab-service-box">
                <figure className="service-thumb">
                  <img
                    src="images/airport-taxi.png"
                    alt="airport-taxi"
                    className="service-car-images"
                    loading="lazy"
                  />
                </figure>

                <div className="service-desc">
                  <h4>Airport Taxi</h4>
                  <p>
                    Prompt airport taxi service for convenient and reliable
                    travel solutions.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-4">
              <div className="cab-service-box">
                <figure className="service-thumb">
                  <img
                    src="images/ambulance-cab.png"
                    alt="ambulance-taxi"
                    className="service-car-images"
                    loading="lazy"
                  />
                </figure>

                <div className="service-desc">
                  <h4>Ambulance Service</h4>
                  <p>
                    Emergency ambulance service for swift medical assistance
                    during critical situations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CareContainer/>
    </Layout>
  );
}

export default React.memo(Services);
